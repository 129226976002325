import React, { useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import LogoImage from 'common/assets/image/cryptoModern/nadi_logo.png';
import LogoImageAlt from 'common/assets/image/cryptoModern/logo.png';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      cryptoModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
        navbarSecond {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);

  const isBrowser = typeof window !== 'undefined';
  let navMenu;
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  const isHomePage = isBrowser && window.location.pathname === '/';

  navMenu = isHomePage
    ? data?.cryptoModernJson?.navbar?.navMenu || []
    : data?.cryptoModernJson?.navbarSecond?.navMenu || [];

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const scrollItems = navMenu.map((item) => item.path.slice(1));

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo href="/#" logoSrc={LogoImage} title="NadiAI" className="main-logo" />
        <Logo href="/#" logoSrc={LogoImageAlt} title="NadiAI" className="logo-alt" />
        <MenuArea className={state.searchToggle ? 'active' : ''}>
          {isHomePage ? (
            <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          ) : (
            <nav className="nav">
              {navMenu.map((menu, index) => (
                 <a href={menu.path}>
                 {menu.label}
                </a>
              ))}
            </nav>
          )}
          <a href="/joinwaitlist" offset={84}>
            <Button className="trail" title="Join wait list" />
          </a>
          <Button
            className="menubar menu"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          {isHomePage ? (
            <Scrollspy
              className="menu"
              items={scrollItems}
              offset={-84}
              currentClassName="active"
            >
              {navMenu.map((menu, index) => (
                <li key={`menu_key${index}`}>
                  <AnchorLink href={menu.path} offset={menu.offset} onClick={handleRemoveMenu}>
                    {menu.label}
                  </AnchorLink>
                </li>
              ))}
            </Scrollspy>
          ) : (
            navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <a href={menu.path} onClick={handleRemoveMenu}>
                  {menu.label}
                </a>
              </li>
            ))
          )}
          <a href="/joinwaitlist">
          <Button title="Join wait list" />
          </a>
        </Container>
      </MobileMenu>
    </NavbarWrapper>
  );
};

export default Navbar;
